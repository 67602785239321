import request from '@/utils/request'

// 查询中国行政地区列表
export function listNation (query) {
  return request({
    url: '/system/nation/list',
    method: 'get',
    params: query
  })
}

// 查询中国行政地区详细
export function getNation (id) {
  return request({
    url: '/system/nation/' + id,
    method: 'get'
  })
}

// 新增中国行政地区
export function addNation (data) {
  return request({
    url: '/system/nation',
    method: 'post',
    data: data
  })
}

// 修改中国行政地区
export function updateNation (data) {
  return request({
    url: '/system/nation',
    method: 'put',
    data: data
  })
}

// 删除中国行政地区
export function delNation (id) {
  return request({
    url: '/system/nation/' + id,
    method: 'delete'
  })
}

// 导出中国行政地区
export function exportNation (query) {
  return request({
    url: '/system/nation/export',
    method: 'get',
    params: query
  })
}
