<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="层级" prop="level" >
        <a-tree-select
          v-model="form.level"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="nationOptions"
          placeholder="请选择"
          :replaceFields="{children:'children', title:'name', key:'id', value: 'id' }"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="父级行政代码" prop="parentCode" >
        <a-input v-model="form.parentCode" placeholder="请输入父级行政代码" />
      </a-form-model-item>
      <a-form-model-item label="行政代码" prop="areaCode" >
        <a-input v-model="form.areaCode" placeholder="请输入行政代码" />
      </a-form-model-item>
      <a-form-model-item label="邮政编码" prop="zipCode" >
        <a-input v-model="form.zipCode" placeholder="请输入邮政编码" />
      </a-form-model-item>
      <a-form-model-item label="区号" prop="cityCode" >
        <a-input v-model="form.cityCode" placeholder="请输入区号" />
      </a-form-model-item>
      <a-form-model-item label="名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item label="简称" prop="shortName" >
        <a-input v-model="form.shortName" placeholder="请输入简称" />
      </a-form-model-item>
      <a-form-model-item label="组合名" prop="mergerName" >
        <a-input v-model="form.mergerName" placeholder="请输入组合名" />
      </a-form-model-item>
      <a-form-model-item label="拼音" prop="pinyin" >
        <a-input v-model="form.pinyin" placeholder="请输入拼音" />
      </a-form-model-item>
      <a-form-model-item label="经度" prop="lng" v-if="formType === 1">
        <a-input v-model="form.lng" placeholder="请输入经度" />
      </a-form-model-item>
      <a-form-model-item label="纬度" prop="lat" v-if="formType === 1">
        <a-input v-model="form.lat" placeholder="请输入纬度" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getNation, addNation, updateNation } from '@/api/system/nation'
import { TreeSelect } from 'ant-design-vue'

export default {
  name: 'CreateForm',
  props: {
    nationOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    ATreeSelect: TreeSelect
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        level: null,
        parentCode: null,
        areaCode: null,
        zipCode: null,
        cityCode: null,
        name: null,
        shortName: null,
        mergerName: null,
        pinyin: null,
        lng: null,
        lat: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        level: [
          { required: true, message: '层级不能为空', trigger: 'blur' }
        ],
        parentCode: [
          { required: true, message: '父级行政代码不能为空', trigger: 'blur' }
        ],
        areaCode: [
          { required: true, message: '行政代码不能为空', trigger: 'blur' }
        ],
        zipCode: [
          { required: true, message: '邮政编码不能为空', trigger: 'blur' }
        ],
        cityCode: [
          { required: true, message: '区号不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        shortName: [
          { required: true, message: '简称不能为空', trigger: 'blur' }
        ],
        mergerName: [
          { required: true, message: '组合名不能为空', trigger: 'blur' }
        ],
        pinyin: [
          { required: true, message: '拼音不能为空', trigger: 'blur' }
        ],
        lng: [
          { required: true, message: '经度不能为空', trigger: 'blur' }
        ],
        lat: [
          { required: true, message: '纬度不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        level: null,
        parentCode: null,
        areaCode: null,
        zipCode: null,
        cityCode: null,
        name: null,
        shortName: null,
        mergerName: null,
        pinyin: null,
        lng: null,
        lat: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.$emit('select-tree')
      if (row != null && row.id) {
        this.form.level = row.id
      } else {
        this.form.level = 0
      }
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.$emit('select-tree')
      this.formType = 2
      const id = row ? row.id : ids
      getNation(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateNation(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addNation(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
